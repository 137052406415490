<script setup>
    import PrimevueTabMenu from 'primevue/tabmenu';

    const props = defineProps({
        model: {
            type: Array,
            default: () => [],
        },
    });

    const index = computed(() => {
        return props.model.findIndex((item) => item.to === useRoute().path);
    });
</script>

<template>
    <PrimevueTabMenu :model="props.model" :activeIndex="index">
        <template #item="slotProps">
            <slot name="item" v-bind="slotProps">
                <NuxtLink class="p-menuitem-link" v-bind="slotProps.item">
                    <span v-if="slotProps.item?.icon" class="p-menuitem-icon">
                        <Icon :name="slotProps.item.icon" />
                    </span>

                    <span class="p-menuitem-text">{{ slotProps.item.label }}</span>
                </NuxtLink>
            </slot>
        </template>
    </PrimevueTabMenu>
</template>

<style lang="scss" scoped>
    .p-tabmenu {
        line-height: 1.25rem !important;
    }

    .p-menuitem-link {
        display: inline-flex;
        gap: .5rem;
    }

    .p-menuitem-text {
        font-size: var(--text-md) !important;

        &:empty {
            display: none;
        }
    }

    .p-menuitem-icon {
        @include flex-center;
        margin: 0 !important;
        font-size: 1.25rem !important;
    }
</style>
